import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import ModalEquipes from '../componentes/ModalEquipes'

export default function Evento() {
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        federacoes: firebase.firestore().collection('federacoes'),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/inscricao/', ''))

    const [validaLote, setValidaLote] = useState(false)
    const [dataNascimento, setDataNascimento] = useState('')
    const [evento, setEvento] = useState({})
    const [federacao, setFederacao] = useState({})
    const [filiacao, setFiliacao] = useState({})
    const [usuario, setUsuario] = useState({})
    const [usuarioProfessor, setUsuarioProfessor] = useState({})
    const [competidores, setCompetidores] = useState([])

    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [inputProfessor, setInputProfessor] = useState('')
    const [equipe, setEquipe] = useState({})
    const [dadosEquipe, setDadosEquipe] = useState({})
    const [professor, setProfessor] = useState({})

    const [categorias, setCategorias] = useState([])
    const [listaAbsolutos, setListaAbsolutos] = useState([])
    const [validaAbsolutos, setValidaAbsolutos] = useState(false)
    const [absolutos, setAbsolutos] = useState([])
    const [absoluto, setAbsoluto] = useState([])
    const [pesos, setPesos] = useState([])
    const [faixas, setFaixas] = useState([])

    const [categoria, setCategoria] = useState({})
    const [peso, setPeso] = useState({})
    const [faixa, setFaixa] = useState({})
    const [inscricoes, setInsrcicoes] = useState([]);

    const [escolheAbsoluto, setEscolheAbsoluto] = useState('')
    const [vouchers, setVouchers] = useState([])
    const [voucher, setVoucher] = useState('')
    const [itemVoucher, setItemVoucher] = useState({})
    const [loading, setLoading] = useState(true)

    const [valorCategoria, setValorCategoria] = useState(0)
    const [valorAbsoluto, setValorAbsoluto] = useState(0)
    const [modalShow, setModalShow] = useState(false);

    // Se houver inclusão de uma nova equipe, atualizar o state Equipe
    useEffect(() => {
        if (Object.keys(dadosEquipe).length > 0) {
            setEquipe(dadosEquipe);
        }
        if (usuario.professor && professores.some(prof => prof.id === usuario.professor)) {
            setProfessor(professores.filter(prof => prof.id === usuario.professor)[0])
        }
    }, [dadosEquipe, professores])

    useEffect(() => {
        servicos.auth.onAuthStateChanged(async (user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            const snapEquipes = await servicos.equipes.where('status', '==', 1).get();
            const equipesData = snapEquipes.docs.map((doc) => doc.data());
            const l = []
            const lFinal = []
            equipesData.forEach((d) => {
                l.push(d.nome)
            })
            const array = [...new Set(l)]
            array.forEach((d) => {
                lFinal.push(equipesData.filter((a) => a.nome === d)[0])
            })
            setEquipes(lFinal.sort((a, b) => {
                if (a.equipe < b.equipe)
                    return -1;
                if (a.equipe > b.equipe)
                    return 1;
                return 0;
            }))

            if (user) {

                servicos.usuarios.doc(user.uid).get().then((doc) => {
                    setUsuario(doc.data())
                    if (doc.data().equipe) {
                        if (lFinal.some(eqp => eqp.id === doc.data().equipe)) {
                            setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])
                        }

                    }
                    servicos.eventos.doc(id).get().then((ev) => {
                        var date2 = moment(doc.data().dataNascimento).format('YYYY')
                        var date1 = moment(ev.data().inicioEvento).format('YYYY')

                        var data1 = moment(date1, 'YYYY');
                        var data2 = moment(date2, 'YYYY');
                        console.log(data1.diff(data2, 'years'))
                        setDataNascimento(data1.diff(data2, 'years'))

                        // Carregar Filiação Federação Vinculada (se houver)
                        if (ev.data().federacaoVinculada) {
                            servicos.federacoes.doc(ev.data().federacaoVinculada).get().then(snap => {
                                if (snap.exists) {
                                    setFederacao(snap.data())

                                    if (doc.data()?.filiacoes && doc.data()?.filiacoes.some(fed => fed === snap.data().id)) {
                                        servicos.federacoes.doc(ev.data()
                                            .federacaoVinculada).collection('filiacoes')
                                            .where('idFiliado', '==', doc.data().id)
                                            .where('tipoUsuario', '==', doc.data().tipoUsuario).get().then(snapFiliacoes => {
                                                if (!snapFiliacoes.empty) {
                                                    const data = snapFiliacoes.docs.map(d => d.data())[0]
                                                    setFiliacao(data);
                                                }
                                            })
                                    }
                                }
                            })
                        }


                        // Carrega Categoria
                        const lista = [];
                        servicos.eventos.doc(id).collection('categorias').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                            const data = snap.docs.map(d => d.data())
                            data.forEach((c) => {
                                if (data1.diff(data2, 'years') >= 18) {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                            console.log(c.minIdade)
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                } else {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                }
                            })
                            setCategorias(lista);

                        })
                        servicos.inscricoes
                            .where('evento.id', '==', id)
                            .where('usuario.id', '==', user.uid).get().then(snap => {
                                if (snap.size > 0) {
                                    const dados = snap.docs.map(d => d.data());
                                    setInsrcicoes(dados);
                                }
                            })

                    })

                    // Carrega Absoluto
                    servicos.eventos.doc(id).collection('absolutos').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                        const data = snap.docs.map(d => d.data())
                        setListaAbsolutos(data);
                    })
                    servicos.eventos.doc(id).collection("vouches").get().then((snap) => {
                        const list = []
                        const data = snap.docs.map((d) => d.data())
                        // setVouchers(data)
                        data.forEach((d) => {
                            servicos.eventos.doc(id).collection("vouches")
                                .doc(d.id)
                                .collection("cupons")
                                .where("status", "==", 0)
                                .get().then((snapCupom) => {
                                    if (snapCupom.size > 0) {
                                        list.push({
                                            grupo: d,
                                            vouchers: snapCupom.docs.map((d) => d.data())
                                        })
                                    }
                                })
                        })
                        setTimeout(() => {
                            setVouchers(list)
                        }, 10000);
                    })
                    setLoading(false)

                    // Carregar Professores
                    if (doc.data().equipe) {

                        servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                            setEquipe(e.data() ? e.data() : {})
                        })

                        if (doc.data().professor) {
                            servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                                setProfessor(e.data() ? e.data() : {})
                            })
                        }


                        servicos.equipes.doc(doc.data().equipe).collection('professores').get().then((snap) => {
                            Swal.close()
                            const data = snap.docs.map((d) => d.data())
                            setProfessores(data)
                        })
                    }

                })
            } else if (idUser) {
                servicos.usuarios.doc(idUser).get().then((doc) => {
                    setUsuario(doc.data())
                    if (doc.data().equipe) {
                        if (lFinal.some(eqp => eqp.id === doc.data().equipe)) {
                            setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])
                        }
                    }
                    servicos.eventos.doc(id).get().then((ev) => {
                        var date2 = moment(doc.data().dataNascimento).format('YYYY')
                        var date1 = moment(ev.data().inicioEvento).format('YYYY')

                        var data1 = moment(date1, 'YYYY');
                        var data2 = moment(date2, 'YYYY');
                        console.log(data1.diff(data2, 'years'))
                        setDataNascimento(data1.diff(data2, 'years'))


                        // if (doc.data().equipe) {
                        //     // Carrega Equipe
                        //     servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                        //         setEquipe(e.data())
                        //     })
                        //     if (doc.data().professor && doc.data().professor !== '0') {
                        //         // Carrega Professor
                        //         servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                        //             if (e.exists) {
                        //                 setProfessor(e.data())
                        //             } else {
                        //                 setProfessor({
                        //                     id: null,
                        //                     professor: 'Não configurado'
                        //                 })
                        //             }
                        //         })
                        //     } else {
                        //         mensagemAlertaEquipe('Para se inscrever no evento, você precisa configurar o professor na equipe. Você será direcionado ao Perfil para completar as informações')
                        //     }
                        // } else {
                        //     mensagemAlertaEquipe('Para se inscrever no evento, você precisa configurar sua Equipe. Você será direcionado ao seu Perfil para completar as informações')
                        // }


                        // Carrega Categoria
                        const lista = [];
                        servicos.eventos.doc(id).collection('categorias').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                            const data = snap.docs.map(d => d.data())
                            data.forEach((c) => {
                                if (data1.diff(data2, 'years') >= 18) {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                            console.log(c.minIdade)
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                } else {
                                    if (c.tipoIdade === 0) {
                                        if (data1.diff(data2, 'years') <= c.idade) {
                                            lista.push(c)
                                        }
                                    } else if (c.tipoIdade === 1) {
                                        if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                            lista.push(c)
                                        }
                                    } else {
                                        if (data1.diff(data2, 'years') > c.idade) {
                                            lista.push(c)
                                        }
                                    }
                                }
                            })
                            setCategorias(lista);
                        })

                        servicos.inscricoes
                            .where('evento.id', '==', id)
                            .where('usuario.id', '==', idUser).get().then(snap => {
                                if (snap.size > 0) {
                                    const dados = snap.docs.map(d => d.data());
                                    setInsrcicoes(dados);
                                }

                            })

                    })

                    // Carrega Absoluto
                    servicos.eventos.doc(id).collection('absolutos').where('sexo', '==', doc.data().sexo).onSnapshot((snap) => {
                        const data = snap.docs.map(d => d.data())
                        setListaAbsolutos(data);
                    })
                    servicos.eventos.doc(id).collection("vouches").get().then((snap) => {
                        const list = []
                        const data = snap.docs.map((d) => d.data())
                        // setVouchers(data)
                        data.forEach((d) => {
                            servicos.eventos.doc(id).collection("vouches")
                                .doc(d.id)
                                .collection("cupons")
                                .where("status", "==", 0)
                                .get().then((snapCupom) => {
                                    if (snapCupom.size > 0) {
                                        list.push({
                                            grupo: d,
                                            vouchers: snapCupom.docs.map((d) => d.data())
                                        })
                                    }
                                })
                        })
                        setTimeout(() => {
                            setVouchers(list)
                        }, 10000);
                    })
                    setLoading(false)

                    // Carrega Professores
                    if (doc.data().equipe) {

                        servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                            setEquipe(e.data())
                        })

                        if (doc.data().professor) {
                            servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then((e) => {
                                setProfessor(e.data())
                            })
                        }

                        servicos.equipes.doc(doc.data().equipe).collection('professores').get().then((snap) => {
                            Swal.close()
                            const data = snap.docs.map((d) => d.data())
                            setProfessores(data)
                        })
                    }

                })



            }
        })
        servicos.eventos.doc(id).get().then((snap) => {
            setEvento(snap.data())
        })
    }, [])

    const handleCategoria = async (event) => {
        setCategoria({})
        setAbsoluto({})
        setPeso({})
        setFaixa({})
        setAbsolutos([])
        setPesos([])
        setFaixas([])
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            mensagemLoading("Carregando informações")

            const hoje = moment(moment().format('YYYY-MM-DD'))
            const listaValoresLotesCategoriaGeral = [];
            const listaValoresLotesCategoriaEspecifico = [];

            const snapLotesCategoriaGeral = await servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', usuario.sexo)
                .where('geral', '==', true)
                .get();

            if (snapLotesCategoriaGeral.size > 0) {
                const lotesCategoriaGeral = snapLotesCategoriaGeral.docs.map(d => d.data())
                for (let doc of lotesCategoriaGeral) {

                    if (moment(doc.vencimento).diff(hoje, 'days') > -1) {
                        listaValoresLotesCategoriaGeral.push(doc.valor)
                    }
                }
            }

            const snapLotesCategoriaEspecifico = await servicos.eventos.doc(evento.id).collection('lotes')
                .where('tipoCategoria', '==', 'categoria')
                .where('sexo', '==', usuario.sexo)
                .where('geral', '==', false)
                .where('categoria.id', '==', event.target.value)
                .get();

            if (snapLotesCategoriaEspecifico.size > 0) {
                const lotesCategoriaEspecifico = snapLotesCategoriaEspecifico.docs.map(d => d.data())

                for (let doc of lotesCategoriaEspecifico) {
                    if (moment(doc.vencimento).diff(hoje, 'days') > -1) {
                        listaValoresLotesCategoriaEspecifico.push(doc.valor)
                    }
                }
            }

            if (listaValoresLotesCategoriaGeral.length === 0 && listaValoresLotesCategoriaEspecifico.length === 0) {
                mensagemAlerta("Lote encerrado para esta categoria. Em caso de dúvida, entre em contato com a Administração.")
                return;
            }
            const _valorCategoria = listaValoresLotesCategoriaEspecifico.length > 0 ? Math.min(...listaValoresLotesCategoriaEspecifico) : Math.min(...listaValoresLotesCategoriaGeral)

            setValorCategoria(_valorCategoria)
            setValidaLote(true)

            servicos.eventos.doc(id).collection("categorias").doc(event.target.value).get().then((snap) => {
                Swal.close()
                setCategoria(snap.data())
            })
            servicos.eventos.doc(id).collection("categorias").doc(event.target.value).collection("pesos").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setPesos(data)
            })
            servicos.eventos.doc(id).collection("categorias").doc(event.target.value).collection("faixas").onSnapshot((snap) => {
                const data = snap.docs.map(doc => doc.data())
                setFaixas(data)
            })
        }
    }

    const handleCompetidor = (event) => {
        setLoading(true)
        setUsuario({})
        event.persist();
        if (event.target.value !== "") {
            setUsuario(competidores[event.target.value])

            var date2 = moment(competidores[event.target.value].dataNascimento).format('YYYY')
            var date1 = moment(evento.inicioEvento).format('YYYY')

            var data1 = moment(date1, 'YYYY');
            var data2 = moment(date2, 'YYYY');
            setDataNascimento(data1.diff(data2, 'years'))

            // Carrega Equipe
            servicos.equipes.doc(competidores[event.target.value].equipe).get().then((e) => {
                setEquipe(e.data())
            })

            // Carrega Professor
            servicos.equipes.doc(competidores[event.target.value].equipe).collection('professores').doc(competidores[event.target.value].professor).get().then((e) => {
                setProfessor(e.data())
            })

            // Carrega Categoria
            const lista = [];
            servicos.eventos.doc(id).collection('categorias').where('sexo', '==', competidores[event.target.value].sexo).onSnapshot((snap) => {
                const data = snap.docs.map(d => d.data())
                data.forEach((c) => {
                    if (data1.diff(data2, 'years') >= 18) {
                        if (c.tipoIdade === 0) {
                            if (data1.diff(data2, 'years') <= c.idade) {
                                lista.push(c)
                            }
                        } else if (c.tipoIdade === 1) {
                            if (c.minIdade >= 18 && data1.diff(data2, 'years') >= c.minIdade) {
                                console.log(c.minIdade)
                                lista.push(c)
                            }
                        } else {
                            if (data1.diff(data2, 'years') > c.idade) {
                                lista.push(c)
                            }
                        }
                    } else {
                        if (c.tipoIdade === 0) {
                            if (data1.diff(data2, 'years') <= c.idade) {
                                lista.push(c)
                            }
                        } else if (c.tipoIdade === 1) {
                            if (data1.diff(data2, 'years') >= c.minIdade && data1.diff(data2, 'years') <= c.maxIdade) {
                                lista.push(c)
                            }
                        } else {
                            if (data1.diff(data2, 'years') > c.idade) {
                                lista.push(c)
                            }
                        }
                    }
                })
                setCategorias(lista);
            })

            // Carrega Absoluto
            servicos.eventos.doc(id)
                .collection('absolutos')
                .where('sexo', '==', competidores[event.target.value].sexo)
                .onSnapshot((snap) => {
                    const data = snap.docs.map(d => d.data())
                    setListaAbsolutos(data);
                })
            setLoading(false)
        }
    }

    const handlePeso = (event) => {
        setPeso({})
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(id).collection("categorias").doc(categoria.id).collection("pesos").doc(event.target.value).get().then((snap) => {
                setPeso(snap.data())
            })
        }
    }

    const handleFaixa = async (event) => {
        mensagemLoading("Carregando informações...")
        setFaixa({})
        setEscolheAbsoluto("")
        event.persist();
        if (event.target.value !== "") {
            servicos.eventos.doc(id).collection("categorias").doc(categoria.id).collection("faixas").doc(event.target.value).get().then((snap) => {
                setFaixa(snap.data())
            })

            const lista = []
            for (let a of listaAbsolutos) {
                 servicos.eventos.doc(id).collection("absolutos").doc(a.id)
                    .collection("idades")
                    .where("id", "==", categoria.id)
                    .get().then(async (i) => {
                        if (i.size > 0) {
                             servicos.eventos.doc(id).collection("absolutos").doc(a.id)
                                .collection("faixas")
                                .where("idCategoria", "==", categoria.id)
                                .where("idFaixa", "==", event.target.value)
                                .get().then((i) => {
                                    if (i.size > 0) {
                                        setValidaAbsolutos(true)
                                        lista.push(a)
                                    }
                                })
                        }
                    })
            }
            if(lista.length == 0){
                await new Promise((resolve) => {
                    setTimeout(resolve, 1500)
                  })
            }
            if(lista.length == 0){
                await new Promise((resolve) => {
                    setTimeout(resolve, 1000)
                  })
            }
            Swal.close();
            setAbsolutos(lista)
        }
    }

    const handleEscolheAbsoluto = (event) => {
        event.persist();
        if (event.target.value !== "") {
            setEscolheAbsoluto(event.target.value)
            if (event.target.value === "0") {
                setValorAbsoluto(0)
                setAbsoluto({})
            }
        }
    }

    const handleAbsoluto = async (event) => {
        event.persist();

        const hoje = moment(moment().format('YYYY-MM-DD'))
        const listaValoresLotesAbsolutoGeral = [];
        const listaValoresLotesAbsolutoEspecifico = [];

        const snapLotesAbsolutoGeral = await servicos.eventos.doc(evento.id).collection('lotes')
            .where('tipoCategoria', '==', 'absoluto')
            .where('sexo', '==', usuario.sexo)
            .where('geral', '==', true)
            .get();

        if (snapLotesAbsolutoGeral.size > 0) {
            const lotesAbsolutoGeral = snapLotesAbsolutoGeral.docs.map(d => d.data());

            for (let doc of lotesAbsolutoGeral) {
                if (moment(doc.vencimento).diff(hoje, 'days') > -1) {
                    listaValoresLotesAbsolutoGeral.push(doc.valor)
                }
            }

        }

        const snapLotesAbsolutoEspecifico = await servicos.eventos.doc(evento.id).collection('lotes')
            .where('tipoCategoria', '==', 'absoluto')
            .where('sexo', '==', usuario.sexo)
            .where('geral', '==', false)
            .where('categoria.id', '==', event.target.value)
            .get();


        if (snapLotesAbsolutoEspecifico.size > 0) {
            const lotesAbsolutoEspecifico = snapLotesAbsolutoEspecifico.docs.map(d => d.data());

            for (let doc of lotesAbsolutoEspecifico) {
                if (moment(doc.vencimento).diff(hoje, 'days') > -1) {
                    listaValoresLotesAbsolutoEspecifico.push(doc.valor)
                }
            }

        }

        if (listaValoresLotesAbsolutoGeral.length === 0 && listaValoresLotesAbsolutoEspecifico.length === 0) {
            mensagemAlerta("Lote vencido! Entre em contato com a administração")
            return;
        }

        const _valorAbsoluto = listaValoresLotesAbsolutoEspecifico.length > 0 ? Math.min(...listaValoresLotesAbsolutoEspecifico) : Math.min(...listaValoresLotesAbsolutoGeral)

        setValorAbsoluto(_valorAbsoluto)

        servicos.eventos.doc(id).collection("absolutos").doc(event.target.value).get().then((doc) => {
            setAbsoluto(doc.data())
        })
    }

    const handleVoucher = (event) => {
        event.persist();
        setItemVoucher({})
        const v = []
        setVoucher(event.target.value)
        let item = 0
        setTimeout(() => {
            if (event.target.value.length === 9) {
                mensagemLoading("Buscando Voucher...")
                vouchers.forEach((d) => {
                    if (d.vouchers.filter((e) => e.voucher === event.target.value).length === 1) {
                        item = 1
                        v.push(d.vouchers.filter((e) => e.voucher === event.target.value)[0])
                    }
                })
                setTimeout(() => {
                    if (v.length === 0) {
                        Swal.close()
                        mensagemAlerta('Nenhum voucher encontrado')
                    } else {
                        setItemVoucher(v[0])
                        Swal.close();
                    }
                }, 5000);
            } else if (event.target.value.length === 8) {
                mensagemLoading("Buscando Voucher...")
                vouchers.forEach((d) => {
                    if (d.vouchers.filter((e) => e.voucher === event.target.value).length > 0) {
                        item = 1
                        v.push(d.vouchers.filter((e) => e.voucher === event.target.value)[0])
                    }
                })
                setTimeout(() => {
                    if (v.length === 0) {
                        Swal.close()
                        mensagemAlerta('Nenhum voucher encontrado')
                    } else {
                        setItemVoucher(v[0])
                        Swal.close();
                    }
                }, 5000);
            } else if (event.target.value.length === 7) {
                mensagemLoading("Buscando Voucher...")

                vouchers.forEach((d) => {
                    if (d.vouchers.filter((e) => e.voucher === event.target.value).length > 0) {
                        item = 1
                        v.push(d.vouchers.filter((e) => e.voucher === event.target.value)[0])
                    }
                })
                setTimeout(() => {
                    if (v.length === 0) {
                        Swal.close()
                        mensagemAlerta('Nenhum voucher encontrado')
                    } else {
                        setItemVoucher(v[0])
                        Swal.close();
                    }
                }, 5000);
            }
        }, 5000);

    }

    function verificaInscricao() {


        if (evento?.inscricoesMultiplas !== 1 && inscricoes.length > 0) {
            Swal.fire(
                {    title: 'Atenção!',
                    html: 'Você já possui uma inscrição. Alterações na inscrição só serão permitidas após a confirmação do pagamento. Se desejar, você pode cancelar a inscrição e refaze-la (caso ainda não tenha realizado pagamento).', 
                    icon: 'warning',
                    customClass: {
                      content: 'swal-message' // Apply custom class to the content
                    }}
                , ).then(() => {
                window.location.href= `/minhaInscricao/${inscricoes[0].id}`
            })
            return;
        }


        // Validações  em caso de Inscrições Multiplas habilitado

        if (inscricoes.length > 0 && Object.keys(peso).length > 0) {

            // Validar se a categoria selecionada de Peso já não existe em outra inscrição.

            const pesoInscrito = inscricoes.some(insc => insc.categoria.peso.id === peso.id);
            if (pesoInscrito) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria de peso.');
                return;
            }

            // Validar se o Absoluto selecionado já não existe em outra inscrição.

            const absolutosNasInscricoes = inscricoes
                .filter(insc => Object.keys(insc.categoria.absoluto).length > 0)
                .map(insc => insc.categoria.absoluto);

            if (absolutosNasInscricoes.some(abs => abs.id === absoluto.id)) {
                mensagemAlerta('Você já possui uma inscrição para esta categoria Absoluto');
                return;
            }

        }

        if (Object.keys(dadosEquipe).length === 0) {
            mensagemAlerta('Por favor, selecione uma equipe.');
            return;
        }
        if (Object.keys(equipe).length === 0) {
            mensagemAlerta('Por favor, selecione uma equipe.');
            return;
        }
        if (Object.keys(professor).length === 0 && professores.length > 0) {
            mensagemAlerta('Por favor, selecione um professor.');
            return;
        }

        // validar se a equipe está selecionada mas não existe professor criado.
        if (equipe.id && professores.length === 0 && inputProfessor.trim().length === 0) {
            mensagemAlerta('Por favor, informe um professor.');
            return;
        }



        //  Demais validações

        if (!categoria.id) {
            mensagemAlerta('Favor escolha sua categoria')
        } else if (!peso.id) {
            mensagemAlerta('Favor escolha a categoria de pesos')
        } else if (!faixa.id) {
            mensagemAlerta('Favor escolha a categoria de faixas')
        } else {
            if (escolheAbsoluto === '1') {
                if (!absoluto.id) {
                    mensagemAlerta('Favor escolha a categoria absoluto')
                } else {
                    Swal.fire({
                        title: 'Deseja gravar seus dados?',
                        text: 'Ao gravar os dados não signifca que está inscrito. A inscrição é feita após o pagamento. É possivel editar a inscrição após a confirmação do pagamento.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não'
                    }).then((result) => {
                        if (result.value) {
                            verificaEquipe()
                        }
                    })
                }
            } else {
                Swal.fire({
                    title: 'Deseja gravar seus dados?',
                    text: 'Ao gravar os dados não signifca que está inscrito. A inscrição é feita após a confirmação do pagamento. Será possivel editar a inscrição após a confirmação do pagamento.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Continuar Editando'
                }).then((result) => {
                    if (result.value) {
                        verificaEquipe()
                    }
                })
            }
        }

    }

    function cadastraCortesia() {
        if (!categoria.id) {
            mensagemAlerta('Favor escolha sua categoria')
            return;
        }
        if (!peso.id) {
            mensagemAlerta('Favor escolha a categoria de pesos')
            return;
        } if (!faixa.id) {
            mensagemAlerta('Favor escolha a categoria de faixas')
            return;
        }

        if (escolheAbsoluto === '1') {
            if (!absoluto.id) {
                mensagemAlerta('Favor escolha a categoria absoluto')
                return;
            }
        }

        let mensagem = ''

        if (['desconto', 'valor'].includes(itemVoucher.tipo)) {
            mensagem = 'Ao gravar os dados não signifca que está inscrito. A inscrição é feita após o pagamento. É possivel editar a inscrião após a confirmação do pagamento. Você tem um Voucher de desconto aplicado'

        } else if ((valorCategoria + valorAbsoluto) === 0) {
            mensagem = 'Você fará sua inscrição gratuitamente';


        } else {
            mensagem = 'Você fará sua inscrição gratuitamente com o Voucher de cortesia aplicado.';
        }

        Swal.fire({
            title: 'Deseja gravar seus dados?',
            text: mensagem,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                verificaEquipe()
            }
        })

    }

    function verificaEquipe() {
        if (Object.keys(dadosEquipe).length === 0) {
            mensagemAlerta('Por favor escolha a equipe que você irá lutar neste evento')
            return
        }
        if (equipe.id && professores.length === 0) {
            if (!inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }
        if (equipe.id && professores.length > 0) {
            if (!professor.id) {
                mensagemAlerta('Por favor selecione seu professor')
                return
            } else if (professor.id === "nao" && !inputProfessor) {
                mensagemAlerta('Por favor digite o nome do seu professor')
                return
            }
        }

        cadastraInscricao(equipe.id, equipe.equipe, professor.id, professor.professor)
    }

    function pagamento() {

        return {
            pagamento: itemVoucher.id && itemVoucher.tipo === 'cortesia' || (valorAbsoluto + valorCategoria) === 0 ? 'cortesia' : null,

        }


    }



    async function cadastraInscricao(idEquipe, nomeEquipe, idProfessor, nomeProfessor) {
        console.log(itemVoucher)
        mensagemLoading('Aguarde salvando sua inscrição')
        let valDesconto = (parseFloat(valorCategoria) + parseFloat(valorAbsoluto)) - ((parseFloat(valorCategoria) + parseFloat(valorAbsoluto)) * parseFloat(itemVoucher.porcentagem) / 100)
        let valValor = (parseFloat(valorCategoria) + parseFloat(valorAbsoluto)) - parseFloat(itemVoucher.valor)

        // Caso houver um novo professor incluído, salvar o professor primeiro para usa-lo como referencia dentroinscrição.

        let professorIncluido = {}

        if (equipe.id && inputProfessor.trim().length > 0) {

            const snapProfessor = await servicos.equipes.doc(dadosEquipe.id).collection('professores').add({
                professor: inputProfessor
            });
            await servicos.equipes.doc(dadosEquipe.id).collection('professores').doc(snapProfessor.id).update({ id: snapProfessor.id });

            professorIncluido = { id: snapProfessor.id }
        }

        if (usuario.equipe !== dadosEquipe.id || usuario.professor !== professor.id) {
            let update = {}
            if (usuario.equipe !== dadosEquipe.id) {
                update = { ...update, equipe: dadosEquipe.id }
            }
            if (usuario.professor !== professor.id) {
                update = { ...update, professor: professor.id }
            }
            if (Object.keys(professorIncluido).length > 0) {
                update = { ...update, professor: professorIncluido.id }
            }
            await servicos.usuarios.doc(usuario.id).update(update);
        }

        servicos.inscricoes.add({
            evento: {
                id: evento.id,
                nome: evento.nome,
                avatar: evento.avatar,
            },
            usuario: {
                id: usuario.id,
                avatar: usuario.avatar ? usuario.avatar : 'https://www.pngitem.com/pimgs/m/105-1052047_jiu-jitsu-hd-png-download.png',
                nome: usuario.nome,
                sobrenome: usuario.sobrenome,
                cpf: usuario.cpf ? usuario.cpf : '',
                ddd: usuario.ddd ? usuario.ddd : '',
                celular: usuario.celular ? usuario.celular : '',
                email: usuario.email,
                sexo: usuario.sexo,
            },
            idade: {
                categoriaIdade: usuario.tipoUsuario === 'professor' || usuario.tipoUsuario === 'responsavel' ? 'menor' : 'maior',
                responsavel: usuario.nome,
                tipoResponsavel: usuario.tipoUsuario === 'responsavel' ? 'responsavel' : 'eu',
                documentoResponsavel: usuario.cpf,
                dataNascimento: usuario.dataNascimento,
            },
            equipe: {
                equipe: idEquipe,
                professor: professorIncluido.id ? professorIncluido.id : idProfessor,
                nomeEquipe: nomeEquipe,
                nomeProfessor: professorIncluido.id ? inputProfessor.toUpperCase() : nomeProfessor,
                avatar: 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg'
            },
            categoria: {
                categoria: categoria,
                peso: peso,
                faixa: faixa,
                idade: categoria,
                absoluto: absoluto,
            },
            pagamento: {
                pedido: null,
                pagamento: valorTotalComDesconto() === 0 ? 'cortesia' : null,
                status: itemVoucher.id && itemVoucher.tipo === 'desconto' ? null : itemVoucher.id && itemVoucher.tipo === 'cortesia' || (valorAbsoluto + valorCategoria) === 0 ? 'approved' : null,
                tipoPagamento: null,
                desconto: itemVoucher.id && itemVoucher.tipo === 'desconto' ? parseFloat(itemVoucher.valor) : 0,
            },
            pagamentoAbsoluto: {
                pedido: null,
                pagamento: null,
                status: null,
                tipoPagamento: null,
                desconto: 0,
            },
            responsavel: {
                id: usuarioProfessor.id ? usuarioProfessor.id : null,
                nome: usuarioProfessor.id ? usuarioProfessor.nome : null,
                sobrenome: usuarioProfessor.id ? usuarioProfessor.sobrenome : null,
                email: usuarioProfessor.id ? usuarioProfessor.email : null
            },
            valorTotalOriginal: parseFloat(valorTotalOriginal().toFixed(2)),
            valorAbsolutoOriginal: parseFloat(valorAbsoluto),
            valorCategoriaOriginal: parseFloat(valorCategoria),
            valorTotal: parseFloat(valorTotalComDesconto().toFixed(2)),
            valorAbsoluto: itemVoucher.id && itemVoucher.tipo === 'cortesia' ? parseInt(0) : parseFloat(valorAbsoluto),
            valorCategoria: itemVoucher.id && itemVoucher.tipo === 'cortesia' ? parseInt(0) : parseFloat(valorCategoria),
            endereco: {
                endereco: usuario.endereco.endereco,
                bairro: usuario.endereco.bairro,
                numero: usuario.endereco.numero,
                complemento: usuario.endereco.complemento,
                uf: usuario.endereco.uf,
                cidade: usuario.endereco.cidade,
                cep: usuario.endereco.cep
            },
            status: valorTotalComDesconto() === 0 ? 2 : 0,
            statusAbsoluto: 0,
            voucher: {
                voucher: itemVoucher.id ? itemVoucher.voucher : null,
                tipo: itemVoucher.id ? itemVoucher.tipo : null,
                valor: itemVoucher.id && itemVoucher.tipo === 'valor' ? parseFloat(itemVoucher.valor) : 0,
                porcentagem: itemVoucher.id && itemVoucher.tipo === 'desconto' ? parseFloat(itemVoucher.porcentagem) : 0,
                valorDescontoAplicado: itemVoucher.id ? parseFloat(descontoVoucherAplicado().toFixed(2)) : null,
                id: itemVoucher.id ? itemVoucher.id : null
            },
            descontoFiliacao: {
                descontoAplicado: exibirDescontoFederacao(),
                tipoDesconto: exibirDescontoFederacao() ? federacao?.regrasFiliacao?.tipoDesconto : null,
                valorDesconto: exibirDescontoFederacao() ? parseFloat(federacao?.regrasFiliacao?.valorDesconto) : null,
                valorDescontoAplicado: exibirDescontoFederacao() ? parseFloat(descontoFiliacaoAplicado().toFixed(2)) : null

            },
            dataCadastro: moment().format('DD/MM/YYYY HH:mm'),
            dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
            posicaoChave: null
        }).then((docRef) => {
            servicos.inscricoes.doc(docRef.id).update({
                id: docRef.id
            }).then(() => {
                if (itemVoucher.id) {
                    servicos.eventos.doc(evento.id).collection('vouches').doc(itemVoucher.idGrupo).collection('cupons').doc(itemVoucher.id).update({
                        status: parseInt(2),
                        atleta: usuario.nome
                    }).then(() => {
                        if (itemVoucher.tipo === 'cortesia') {
                            mensagemSucesso('Você está inscrito no Evento ! Você será redirecionado a página do evento para acompanhar sua inscrição', docRef.id)
                        } else {
                            mensagemSucesso('Inscrição salva com sucesso! Você será redirecionado ao pagamento', docRef.id)
                        }
                    })
                } else if (valorTotalComDesconto() === 0) {
                    mensagemSucessoGratuito(docRef.id, 'Inscrição salva com sucesso! Você será redirecionado ao pagamento')
                } else {
                    mensagemSucesso('Inscrição salva com sucesso! Você será redirecionado ao pagamento', docRef.id)
                }
            }).catch(() => {
                Swal.close();
                mensagemErro('Erro ao atualizar a inscrição')
            })
        }).catch(() => {
            mensagemErro('Erro ao cadastrar a inscrição! Tente novamente mais tarde')
        })
    }

    function calculaValorTotal() {
        return valorCategoria + valorAbsoluto
    }

    function mensagemAtencao(msg) {
        Swal.fire('Atenção', msg, 'warning')
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemAlertaEquipe(msg) {
        Swal.fire('Alerta', msg, 'warning').then(() => {
            window.location = window.location.href.replace('/inscricao/' + id, '/perfil/')
        })
    }
    function mensagemSucesso(msg, idInscricao) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
                window.location = window.location.href.replace('/inscricao/' + id, '/evento/' + evento.id)
            } else if ((valorAbsoluto + valorCategoria) === 0) {
                window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + id)
            } else {
                window.location.href = `/pagamento?e=${id}&i=${idInscricao}`
            }

        })
    }
    function mensagemSucessoGratuito(idInsc, msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + idInsc)
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    const handleProfessor = (event) => {
        setInputProfessor("")
        event.persist();
        if (event.target.value === "nao") {
            setProfessor({
                id: "nao"
            })
        } else {
            setProfessor(professores[event.target.value])
        }

    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value)
    }

    function exibirDescontoFederacao() {
        return (
            federacao?.regrasFiliacao?.descontoFiliadosEvento === 1 &&
            filiacao?.statusFiliacao === 2
        )
    }


    function descontoVoucherAplicado() {
        let valor = calculaValorTotal();
        let descontoAplicado = 0

        if (itemVoucher.id) {
            if (itemVoucher.porcentagem) {
                descontoAplicado = (valor * parseFloat(itemVoucher.porcentagem) / 100)
            }
            if (itemVoucher.valor) {
                descontoAplicado = parseFloat(itemVoucher.valor)
            }
        }

        return descontoAplicado
    }

    function descontoFiliacaoAplicado() {

        let valor = calculaValorTotal();
        let valorDesconto = 0;

        if (itemVoucher.id) {
            if (itemVoucher.porcentagem) {
                valor = valor - (valor * parseFloat(itemVoucher.porcentagem) / 100)
            }
            if (itemVoucher.valor) {
                valor = valor - parseFloat(itemVoucher.valor)
            }
        }

        if (federacao?.regrasFiliacao?.tipoDesconto === '1') {
            valorDesconto = (valor * (parseFloat(federacao?.regrasFiliacao?.valorDesconto) / 100))
        }
        if (federacao?.regrasFiliacao?.tipoDesconto === '2') {
            valorDesconto = parseFloat(federacao?.regrasFiliacao?.valorDesconto)
        }


        return valorDesconto;

    }

    function valorTotalComDesconto() {
        let valor = calculaValorTotal();

        if (itemVoucher.id) {
            if (itemVoucher.porcentagem) {
                valor = valor - (valor * parseFloat(itemVoucher.porcentagem) / 100)
            }
            if (itemVoucher.valor) {
                valor = valor - parseFloat(itemVoucher.valor)
            }
        }
        if (exibirDescontoFederacao()) {
            if (federacao?.regrasFiliacao?.tipoDesconto === '1') {
                valor -= (valor * (parseFloat(federacao?.regrasFiliacao?.valorDesconto) / 100))
            }
            if (federacao?.regrasFiliacao?.tipoDesconto === '2') {
                valor -= parseFloat(federacao?.regrasFiliacao?.valorDesconto)
            }
        }

        if (itemVoucher.tipo === 'cortesia' || valor <= 0) {
            valor = 0
        }

        return valor;
    }

    function valorTotalOriginal() {
        let valor = calculaValorTotal();

        if (itemVoucher.id) {
            if (itemVoucher.porcentagem) {
                valor = valor - (valor * parseFloat(itemVoucher.porcentagem) / 100)
            }
            if (itemVoucher.valor) {
                valor = valor - parseFloat(itemVoucher.valor)
            }
        }
        if (exibirDescontoFederacao()) {
            if (federacao?.regrasFiliacao?.tipoDesconto === '1') {
                valor -= (valor * (parseFloat(federacao?.regrasFiliacao?.valorDesconto) / 100))
            }
            if (federacao?.regrasFiliacao?.tipoDesconto === '2') {
                valor -= parseFloat(federacao?.regrasFiliacao?.valorDesconto)
            }
        }
        // Este caso não tinha antes
        if (itemVoucher.tipo === 'cortesia' || valor <= 0) {
            valor = 0
        }

        return valor;
    }



    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Inscrição no Evento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Abaixo preencha o formulário para se inscrever no evento</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class=" vc_col-sm-6">
                                            <div class="wpb_single_image wpb_content_element vc_align_left">
                                                <figure class="wpb_wrapper vc_figure">
                                                    <div class="vc_single_image-wrapper package-style-1 vc_box_border_grey">
                                                        <img width="1920" height="1401"
                                                            src={evento.avatar}
                                                            class="vc_single_image-img attachment-full" alt=""
                                                            srcset={evento.avatar}
                                                            sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                                                </figure>
                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                    <h3>Observações</h3>
                                                    <p>1 - Seus Dados pessoais são carregados automaticamente e não são alterados. Caso deseja alterá-los, vá até seu perfil e faça a modificação.</p>
                                                    <p>2 - Para realizar sua inscrição na competição, é necessário seguir as etapas abaixo</p>
                                                    <p><b>Escolha sua Categoria</b> - A mesma é carregada automaticamente pelas suas informações de Sexo e Idade</p>
                                                    <p><b>Escolha o Peso</b> - Os pesos são carregados após a escolha da categoria, com isso você deve escolher entre todas as categorias de pesos cadastradas para a competição</p>
                                                    <p><b>Escolha a Faixa</b> - As faixas são carregadas após a escolha da categoria, com isso você deve escolher entre todas as categorias de faixas cadastradas para a competição</p>
                                                    <p><b>Categoria Absoluto</b> - A categoria Absoluto é de escolha sua, ela será carregada pegando as suas informações de Sexo e Idade </p>
                                                    <p><b>Valores das categorias</b> - Os valores são referenciados em forma de Lotes, logo os valores podem mudar no decorrer dos dias de aproximação do evento. Verifique os valores dos lotes na página de detalhe do evento. </p>
                                                    <p><b>Formas de pagamento</b> - Após a escolha de sua categoria e/ou da categoria Absoluto, vocẽ será redirecionado ao total do valor a ser pago para participação da competição. La você terá as informações de como realizar os pagamentos </p>
                                                    <p><b>Dados da Inscrição</b> - A sua inscrição só é confirmada após a confirmação do pagamento via BOLETO ou CARTÂO DE CRÈDITO. Caso deseja alterar suas informações, faça isso antes de escolher a forma de pagamento para evitar problemas futuros. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados Pessoais</h3>
                                                            </div>

                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-5">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Nome</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.nome + " " + usuario.sobrenome}
                                                                            disabled
                                                                            placeholder="Nome *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>CPF</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.cpf}
                                                                            disabled
                                                                            placeholder="Cpf *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sexo</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.sexo}
                                                                            disabled
                                                                            placeholder="Sexo *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Idade</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={dataNascimento + " anos"}
                                                                            disabled
                                                                            placeholder="Idade *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-2">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>DDD</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.ddd}
                                                                            disabled
                                                                            placeholder="DDD *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-3">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Celular</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.celular}
                                                                            disabled
                                                                            placeholder="Celular *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-4">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Tipo</h6>
                                                                        <input
                                                                            type="text" name="your-name" size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            value={usuario.tipoUsuario}
                                                                            disabled
                                                                            placeholder="Tipo *" />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Equipe</h6>
                                                                        <input type='text' name="select"
                                                                            class="form-control"
                                                                            value={dadosEquipe?.equipe ? dadosEquipe?.equipe : ''}
                                                                            placeholder='Selecione uma equipe'
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => setModalShow(true)}
                                                                        />
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Seu Professor</h6>
                                                                        {equipe.id && professores?.length === 0 ?
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={inputProfessor}
                                                                                onChange={handleInputProfessor}
                                                                                placeholder="Nome do Professor *" />
                                                                            :
                                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor} >
                                                                                <option value="">Escolha seu Professor </option>
                                                                                <option value="nao">Não está na lista </option>
                                                                                {professores.sort((a, b) => {
                                                                                    if (a.professor < b.professor)
                                                                                        return -1;
                                                                                    if (a.professor > b.professor)
                                                                                        return 1;
                                                                                }).map((eq, index) =>
                                                                                    <option value={index} selected={usuario.professor === eq.id}>{eq.professor.toUpperCase()}</option>
                                                                                )}
                                                                            </select>
                                                                        }
                                                                    </span>
                                                                </p>
                                                                {professor?.id === "nao" ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Nome Professor</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                value={inputProfessor}
                                                                                onChange={handleInputProfessor}
                                                                                placeholder="Nome do Professor *" />
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="vc_column-inner">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                <h3>Dados da Inscrição</h3>
                                                                <p><b>Selecione abaixo a Categoria, Peso, Faixa ou absoluto para realizar sua inscrição</b></p>
                                                                {inscricoes.length > 0 && (
                                                                    <h4>* Atenção: Esta é a sua {inscricoes.length + 1}º inscrição. </h4>
                                                                )}
                                                            </div>
                                                            <div role="form" class="row " >
                                                                <p class="contact-form-name vc_col-sm-12">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Sua Categoria</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleCategoria} >
                                                                            <option value="">Escolha a categoria </option>
                                                                            {categorias.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((c) =>
                                                                                <option value={c.id} >CATEGORIA {c.categoria.toUpperCase()} - {c.tipoIdade === 0 ? "ATÉ " + c.idade + " ANOS" : c.tipoIdade === 1 ? "DE " + c.minIdade + " ATÉ " + c.maxIdade + " ANOS" : "ACIMA DE " + c.idade}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Peso</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handlePeso} >
                                                                            <option value="">Escolha o peso </option>
                                                                            {pesos.sort((a, b) => {
                                                                                return a.peso - b.peso
                                                                            }).map((c) =>
                                                                                <option value={c.id} >{c.nome.toUpperCase()} - {c.tipoPeso === 0 ? "ATÉ " + c.peso + " KG" : "ACIMA " + c.peso + " KG"}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                <p class="contact-form-name vc_col-sm-6">
                                                                    <span class="wpcf7-form-control-wrap your-name">
                                                                        <h6>Faixa</h6>
                                                                        <select name="select" id="select" class="form-control" onChange={handleFaixa} >
                                                                            <option value="">Escolha a faixa </option>
                                                                            {faixas.sort((a, b) => {
                                                                                return parseInt(a.posicao) - parseInt(b.posicao)
                                                                            }).map((f) =>
                                                                                <option value={f.id} >{f.faixa.toUpperCase()}</option>
                                                                            )}
                                                                        </select>
                                                                    </span>
                                                                </p>
                                                                {faixa.id && validaAbsolutos ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Incluir Absoluto?</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleEscolheAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                <option value="1">SIM </option>
                                                                                <option value="0">NÃO </option>
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {escolheAbsoluto === "1" ?
                                                                    <p class="contact-form-name vc_col-sm-6">
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Escolha o Absoluto</h6>
                                                                            <select name="select" id="select" class="form-control" onChange={handleAbsoluto} >
                                                                                <option value="">Escolha abaixo </option>
                                                                                {absolutos.map((f) =>
                                                                                    <option value={f.id} >{f.absoluto.toUpperCase()}</option>
                                                                                )}
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {categoria.id && peso.id && faixa.id ?
                                                                    <p class="contact-form-name vc_col-sm-6" >
                                                                        <span class="wpcf7-form-control-wrap your-name">
                                                                            <h6>Voucher</h6>
                                                                            <input
                                                                                type="text" name="your-name" size="40"
                                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false"
                                                                                onChange={handleVoucher}
                                                                                value={voucher}
                                                                                placeholder="Você possui Voucher? Digite aqui" />
                                                                        </span>
                                                                    </p>
                                                                    :
                                                                    null
                                                                }
                                                                {peso.id && faixa.id ?
                                                                    <table style={{ width: "100%" }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Categoria</th>
                                                                                <th class="schedule-table__day">Peso</th>
                                                                                <th class="schedule-table__day">Faixa</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6>{categoria.categoria}</h6></td>
                                                                                <td><h6>{peso.tipoPeso === 0 ? "ATÉ " + peso.peso + " KG" : "ACIMA " + peso.peso + " KG"}</h6></td>
                                                                                <td><h6>{faixa.faixa.toUpperCase()}</h6></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    null
                                                                }

                                                                {absoluto.id ?
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Absoluto</th>
                                                                                <th class="schedule-table__day">Idade</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6>{absoluto.absoluto}</h6></td>
                                                                                <td><h6>{categoria.tipoIdade === 0 ? "ATÉ " + categoria.idade + " ANOS" : categoria.tipoIdade === 1 ? "DE " + categoria.minIdade + " ATÉ " + categoria.maxIdade + " ANOS" : "ACIMA DE " + categoria.idade}</h6></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    null
                                                                }
                                                                <table style={{ width: "100%", marginTop: 20 }}>
                                                                    <thead>
                                                                        <tr >
                                                                            <th class="schedule-table__day">Extrato</th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                                <table style={{ width: "100%" }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ height: 50 }}>Valor da Categoria</td>
                                                                            <td>{valorCategoria.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                            <td>{valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td style={{ height: 50 }}><h6>Valor total Categorias</h6></td>
                                                                            <td><h6>{calculaValorTotal().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>
                                                                        </tr>

                                                                        {(itemVoucher.id && itemVoucher.tipo === "desconto") && (
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6 >Voucher de {itemVoucher.tipo} aplicado</h6></td>

                                                                                <td><h6>- {(calculaValorTotal() * parseFloat(itemVoucher.porcentagem) / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({itemVoucher.porcentagem}%)</h6></td>
                                                                            </tr>
                                                                        )}
                                                                        {(itemVoucher.id && itemVoucher.tipo === "cortesia") && (
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6 >Voucher de {itemVoucher.tipo} aplicado</h6></td>

                                                                                <td><h6>GRÁTIS</h6></td>
                                                                            </tr>
                                                                        )}
                                                                        {(itemVoucher.id && itemVoucher.tipo === "valor") && (
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6 >Voucher de {itemVoucher.tipo} aplicado</h6></td>

                                                                                <td><h6 >- {parseFloat(itemVoucher.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>
                                                                            </tr>
                                                                        )}

                                                                        {
                                                                            ((exibirDescontoFederacao() && (!itemVoucher?.tipo === "cortesia")) ||
                                                                                (exibirDescontoFederacao() && (!itemVoucher?.id)) ||
                                                                                (exibirDescontoFederacao() && (['valor', 'desconto'].includes(itemVoucher?.tipo))))
                                                                            && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>Desconto de filiação aplicado</h6></td>
                                                                                    {federacao?.regrasFiliacao?.tipoDesconto === '1' ? (
                                                                                        <td><h6>- {descontoFiliacaoAplicado().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({federacao?.regrasFiliacao?.valorDesconto}%)</h6></td>
                                                                                    ) :
                                                                                        (
                                                                                            <td><h6>- {parseFloat(federacao?.regrasFiliacao?.valorDesconto).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                            )}
                                                                        {(itemVoucher.id || exibirDescontoFederacao()) && (
                                                                            <tr>
                                                                                <td style={{ height: 50 }}><h6 style={{ color: "green" }}>Valor Total com desconto</h6></td>

                                                                                <td><h6 style={{ color: "green" }}>{valorTotalComDesconto().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>

                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                                {validaLote ?
                                                                    <>
                                                                        {itemVoucher.id && itemVoucher.tipo === "desconto" || itemVoucher.id && itemVoucher.tipo === "valor" ?
                                                                            <div onClick={() => cadastraCortesia()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                <input type="submit" value="Salvar e ir para pagamento " class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                            </div>
                                                                            : itemVoucher.id && itemVoucher.tipo === "cortesia" || valorTotalComDesconto() === 0 ?
                                                                                <div onClick={() => cadastraCortesia()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                    <input type="submit" value="Inscrever gratuitamente" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                                </div>
                                                                                :
                                                                                <div onClick={() => verificaInscricao()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                                                    <input type="submit" value="Salvar e ir para pagamento" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={setEquipe} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores}
                    setDadosProfessor={setProfessor}
                />
            )}
        </div>
    );
}
